@import "src/theme/exports";

.list {
  display: block;
  margin: 0;
  padding: 8px 0;
  background: var(--background-color, #fff);
  list-style-type: none;
  contain: content;
}
