.mat-calendar.date-picker-material {
  width: 350px !important;
  height: 385px !important;
  color: var(--dark-grey);
  font-family: var(--font-family-base);
  font-weight: var(--gotham-book);

  .mat-calendar-table-header th {
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: var(--gotham-bold);
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  .mat-calendar-body-cell-content {
    color: var(--medium-grey);

    &.mat-calendar-body-today {
      border: none;
      background-color: none;
    }
  }

  .mat-calendar-body-label {
    color: transparent;
  }

  .mat-calendar-previous-button.mat-icon-button {
    color: var(--green);
  }

  .mat-calendar-next-button.mat-icon-button {
    color: var(--green);
  }

  .mat-calendar-arrow {
    color: var(--green);
  }

  .mat-calendar-body-cell {
    &:hover {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        background-color: var(--white);
      }
    }
  }

  .mat-calendar-body-cell.mat-calendar-body-disabled {
    color: transparent;

    .mat-calendar-body-cell-content {
      color: var(--secondary-210);
    }
  }

  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    color: var(--white);
    box-shadow: none;
    background-color: var(--green);
  }

  .mat-button-wrapper {
    font-family: var(--font-family-base);
  }
}
