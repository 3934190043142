@function side-padding($size) {
  $grid-width: map-get($grid-max-widths, $size);
  $column-padding: map-get($grid-padding-widths, $size);
  $side-padding: calc((100vw - #{$grid-width} - #{$column-padding}) / 2);

  @return $side-padding;
}

.grid-auto-padding-left {
  padding-left: 0;
}

.grid-auto-padding-right {
  padding-right: 0;
}

.grid-lite {
  padding: 5px 15px 10px;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .grid-auto-padding-left {
    padding-left: side-padding(sm) !important;
  }

  .grid-auto-padding-right {
    padding-right: side-padding(sm) !important;
  }

  .grid-lite {
    padding: 5px 10%;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .grid-auto-padding-left {
    padding-left: side-padding(md) !important;
  }

  .grid-auto-padding-right {
    padding-right: side-padding(md) !important;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .grid-auto-padding-left {
    padding-left: side-padding(lg) !important;
  }

  .grid-auto-padding-right {
    padding-right: side-padding(lg) !important;
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .grid-auto-padding-left {
    padding-left: side-padding(xl) !important;
  }

  .grid-auto-padding-right {
    padding-right: side-padding(xl) !important;
  }
}
