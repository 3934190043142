.padding {
  padding: 16px;
}

.padding-top {
  padding-top: 16px;
}

.padding-bottom {
  padding-bottom: 16px;
}

.padding-horizontal {
  padding-right: initial;
  padding-left: initial;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.padding-vertical {
  padding-top: 16px;
  padding-bottom: 16px;
}
