.l-company-info {
  margin-bottom: 40px;

  &__title {
    margin-bottom: 15px;
    padding-bottom: 14px;
    border-bottom: solid 1px var(--secondary-250);
  }

  &__name {
    width: 70%;
    overflow: hidden;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }

  &__detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    &--margin {
      margin-top: 25px;
    }
  }

  &__value {
    display: block;
    margin-top: 5px;
  }

  &__edit-section {
    display: flex;
    flex-direction: column;
  }
}
