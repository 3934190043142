/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Material Icons */
@import "~material-icons/iconfont/material-icons.css";

/* Partials */
@import "theme/exports";
@import "theme/partials/all";

/* Global */

app-content,
body {
  background-color: var(--secondary-100);
  font-family: "Gotham", -apple-system, "Helvetica Neue", "Roboto", sans-serif;
}

.c-loading {
  --spinner-color: var(--green);

  .loading-content {
    font-weight: var(--gotham-light);
  }
}

// Ensure hidden display on swiper pagination
.swiper-container.hide-pager .swiper-pagination {
  display: none;
}

// Wow: Ensure hidden visibility on wow elements prevents some
// strange behaviours
.wow {
  visibility: hidden;
}

.swiper-pagination {
  position: unset !important;
  pointer-events: none;

  .swiper-pagination-bullet {
    opacity: 1;
    margin: 0 2px;
    background: var(--secondary-210);
  }

  .swiper-pagination-bullet-active {
    background: var(--green);
  }
}

.cdk-overlay-pane.cookies-modal {
  height: auto;
}

.video-detail--fullscreen {
  app-content,
  main.content-container {
    .grid-lite {
      padding: 0;
    }

    .d-none {
      display: none !important;
    }
  }

  &::part(content) {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    max-height: 100% !important;
    border: none !important;
    transition: none !important;
  }

  app-media-player .plyr.plyr--video.plyr--paused::before {
    left: 0;
  }
}

.introjs-tooltip {
  color: var(--white);
  background-color: var(--green);

  .introjs-skipbutton {
    display: none;
  }

  .introjs-tooltip-title {
    font-weight: var(--gotham-bold);
  }

  .introjs-tooltiptext {
    font-weight: var(--gotham-book);
  }
}

/* stylelint-disable */
.introjs-tooltipReferenceLayer * {
  font-family: var(--font-family-base);
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 0%) 0 0 1px 2px, rgb(33 33 33 / 50%) 0 0 0 5000px !important;
}
/* stylelint-enable */

.no-padding-horizontal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

app-icon-button {
  button.dark {
    color: var(--white) !important;
    background: var(--publication-dark) !important;
    font-size: 16px !important;
    font-weight: var(--gotham-medium) !important;
  }
}

mat-progress-bar .mat-progress-bar-buffer {
  background-color: var(--border-grey);
}

.mat-progress-bar-fill::after {
  background-color: var(--green);
}

.c-pointer {
  cursor: pointer;
}

.notification-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--notification-color);
}

.header-container {
  position: relative;
  z-index: 10;
  display: block;
  -ms-flex-order: -1;
  order: -1;
  width: 100%;
}

.m-popover-panel.m-popover-style-hidden {
  display: none;
}

.m-popover-panel.m-popover-style-custom {
  --popover-background-color: var(--green);

  transform: translateX(-16px) translateY(16px) !important;

  /* stylelint-disable */
  &.m-popover-position-bottomLeft {
    transform: translateX(16px) translateY(16px) !important;
  }
  /* stylelint-enable */

  .info-popover__content {
    max-width: 230px;
    color: var(--white);
    background-color: var(--green);
    font-size: 12px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-width: 260px;
      font-size: 16px;
    }
  }
}

/* stylelint-disable */
.m-popover-panel.m-popover-position-bottomRight::after,
.m-popover-panel.m-popover-position-bottomLeft::after {
  top: -1rem !important;
  width: auto !important;
}

.m-popover-panel.m-popover-position-top::after {
  bottom: -1rem !important;
  width: auto !important;
}
/* stylelint-enable */
