.toggle-ios {
  width: 50px;
  height: 25px;
  border-radius: 16px;

  .toggle-inner {
    width: 21px;
    height: 21px;
  }

  .toggle-icon::before,
  .toggle-icon {
    background-color: var(--secondary-225);
  }
}

.toggle-ios.toggle-checked {
  .toggle-icon {
    background-color: var(--primary);
  }

  .toggle-inner {
    left: 8px;
  }
}
