.c-ptrab-spinner {
  padding: 150px 0;
  text-align: center;

  &__wheel {
    color: var(--light-grey);

    circle {
      stroke: var(--light-grey);
    }
  }

  &__text {
    display: block;
    padding: 10px;
    color: var(--light-grey);
    font-size: 15px;
  }
}
