@import "src/theme/exports";

.radio-input {
  input[type="radio"] {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    cursor: pointer;
    accent-color: var(--green);
  }

  label {
    cursor: pointer;
  }

  &.radio-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
