@mixin paginator-responsive() {
  /* stylelint-disable */
  ::ng-deep {
    /* stylelint-enable */
    main.content-container {
      padding-bottom: 64px;
      overflow-x: hidden;
    }
  }

  app-content.is-webview {
    height: 100%;
  }
}

@mixin darkmode() {
  app-content.darkmode,
  main.content-container.darkmode,
  footer.darkmode,
  section.darkmode {
    background-color: var(--darkmode-grey);
  }

  .darkmode {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    div,
    label,
    strong {
      color: var(--white) !important;
    }
  }
}

@mixin background-image($default, $minImage) {
  background-image: url($default);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-height: $laptop-client-height) {
    background-image: url($minImage);
  }
}

@mixin centered-spinner($desktop-top, $desktop-left) {
  position: absolute;
  top: 45%;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    // left: calc(50% - 40px);
    top: $desktop-top;
    left: $desktop-left;
    width: 80px;
    height: 80px;
  }
}

@mixin color-hover-desktop($hover-color, $element: "") {
  @media not all and (hover: none) and (pointer: coarse) {
    --color-hover: $hover-color;

    &:hover #{$element} {
      color: $hover-color;
    }
  }
}

@mixin background-hover-desktop($hover-background-color) {
  @media not all and (hover: none) and (pointer: coarse) {
    --background-hover: $hover-background-color;

    &:hover {
      --background: $hover-background-color;

      background-color: $hover-background-color;
    }
  }
}

/*
 * This mixin set Height of the Plyr Video
 */
@mixin plyrHeight($mobileMax, $desktopMax, $fullscreenMax) {
  .platform-ios & {
    .plyr {
      :not(.plyr--fullscreen-fallback).plyr--video {
        video,
        iframe {
          max-height: $desktopMax;
        }
      }
    }
  }

  .plyr.plyr--fullscreen-fallback.plyr--video {
    video,
    iframe {
      max-height: $fullscreenMax !important;
    }
  }

  .plyr:not(:fullscreen).plyr--video {
    video,
    iframe {
      max-height: $mobileMax;

      @media (min-width: map-get($grid-breakpoints, md)) {
        max-height: $desktopMax;
      }
    }
  }
}

@mixin popover-arrow() {
  position: absolute;
  top: 65px;
  z-index: 11;
  display: block;
  visibility: hidden;
  width: 10px;
  height: 10px;
  border-top-left-radius: 1px;
  box-shadow: -2px -2px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  transform: rotate(45deg);
}

/* Ionic mixins */

// Add position for all directions
// @param {string} $top
// @param {string} $end
// @param {string} $bottom
// @param {string} $start
// ----------------------------------------------------------
@mixin position($top: null, $end: null, $bottom: null, $start: null) {
  top: $top;
  right: $end;
  bottom: $bottom;
  left: $start;
}
