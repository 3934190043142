$color: var(--dark-grey);

%input {
  height: 56px;
  border: 1px solid var(--secondary-210);
  outline: none;
  font-size: 16px;
  font-weight: var(--gotham-medium);
  line-height: 18px;
}

.c-form-item {
  --padding: 17px;
  --placeholder-color: var(--light-grey);

  app-input,
  .form-input {
    --background: var(--white);
    --padding-top: var(--padding);
    --padding-bottom: var(--padding);
    --padding-start: var(--padding);
    --padding-end: var(--padding);
    --color: #{$color};

    @extend %input;
  }

  .reduce-placeholder {
    font-size: 12px !important;
    text-overflow: ellipsis !important;
    pointer-events: none !important;
  }

  .c-input,
  .form-input {
    padding: var(--padding);
    color: $color;

    @extend %input;

    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  .form-input {
    width: 100%;
  }

  &.has-error app-input,
  &.has-error .form-input,
  &.has-error .c-input {
    border-color: var(--red);
  }
}

.block-mobility-form-item {
  .title {
    width: 70%;
    margin-right: 10px;
    word-break: break-all;
  }
}

// hide arrows for number input (webkit)
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// hide arrows for number input (mozilla)
input[type="number"] {
  -moz-appearance: textfield;
}
