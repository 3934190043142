@import "src/theme/exports";

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.auto {
    width: auto;
  }

  .col {
    position: relative;
    right: calc(var(--pull, inherit) * 100% / 12);
    left: calc(var(--push, inherit) * 100% / 12);
    display: inline;
    width: calc(var(--columns, auto) * 100% / 12);
    margin-left: calc(var(--offset, 0) * 100% / 12);
    padding: 5px;

    @include column-size();

    @media (min-width: map-get($grid-breakpoints, xs)) {
      @include column-xs-size();
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      @include column-md-size();
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      @include column-lg-size();
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      @include column-xl-size();
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.no-padding-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
