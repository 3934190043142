// https://github.com/valor-software/ng2-dragula

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  z-index: 9999 !important;
  opacity: 0.8;
  margin: 0 !important;
  pointer-events: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);

  img {
    width: 141px;
    max-width: none;
    height: 141px;
    object-fit: cover;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 105px;
      max-width: none;
      height: 105px;
    }
  }

  .example-form-images__bin,
  mat-radio-button {
    display: none;
  }
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
