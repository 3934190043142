@import "src/theme/exports";

.cdk-overlay-pane {
  .mat-dialog-container {
    padding: 0;
    overflow: inherit;
    box-shadow: none;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 760px;
      border-width: 1px;
      border-style: solid;
      border-color: var(--secondary-200);
      border-radius: 0;
    }
  }

  &.hide-overflow {
    overflow: hidden;
  }

  &.base-modal {
    // @media (min-width: map-get($grid-breakpoints, sm)) {
    //   max-height: 90%;
    // }

    @media (min-width: map-get($grid-breakpoints, md)) {
      height: 90%;
      transition: max-height 500ms ease-in-out;
    }

    h1 {
      margin: 0 0 35px;
      color: var(--anthracite);
      font-size: 24px;
      font-weight: var(--gotham-bold);
    }

    &--confirmation {
      @media (min-width: map-get($grid-breakpoints, md)) {
        padding: 0 50px;
      }
    }

    &--success {
      @media (min-width: map-get($grid-breakpoints, md)) {
        max-height: 500px !important;
      }

      &--bigger {
        @media (max-width: map-get($grid-breakpoints, sm)) {
          max-height: 100% !important;

          .content-wrapper {
            height: 100%;
          }

          .reverse-display {
            flex-direction: column !important;
            justify-content: space-evenly;
            height: 80%;
          }
        }
      }
    }
  }

  &.share-modal {
    align-items: flex-end;

    @media (min-width: map-get($grid-breakpoints, md)) {
      align-items: center;
    }

    .content-wrapper {
      max-height: 290px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        max-height: 500px;
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        max-height: 530px;
      }

      .u-h4 {
        font-size: 20px;
        font-weight: var(--gotham-medium);

        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 24px;
          font-weight: var(--gotham-bold);
        }
      }

      .u-h6 {
        display: inline-block;
        width: 80%;
        margin-left: 10px;

        @media (min-width: map-get($grid-breakpoints, md)) {
          width: auto;
          margin-left: 14px;
        }
      }

      ion-icon {
        align-self: flex-start;
        font-size: 24px;
      }
    }
  }

  &.fullscreen {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    min-height: 100vh;
    border: none;

    .mat-dialog-container {
      width: 100%;
    }
  }

  &.small-modal {
    @media (min-width: map-get($grid-breakpoints, md)) {
      height: 400px;
    }
  }

  &.code-validation-modal {
    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-height: 90%;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      min-height: 655px;
    }
  }

  &.cookies-modal {
    position: absolute !important;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100% !important;
    max-width: 100% !important;
    height: 65%;
    border-radius: 0;

    @media (min-width: map-get($grid-breakpoints, md)) {
      height: 42%;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      height: 37%;
    }

    .mat-dialog-container {
      width: 100% !important;
    }
  }

  &.hidden {
    display: none;
  }
}

.confirmation-modal {
  @media (min-width: map-get($grid-breakpoints, md)) {
    height: 100%;
    max-height: 350px;
    padding: 0 50px;

    .mat-dialog-container {
      height: 100%;
    }
  }
}

.example-forms--fullscreen {
  .media-content-modal {
    .content-outer-wrapper {
      padding: 0; /* iOS 13.x */

      @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0; /* iOS 13.x */
      }
    }
  }
}

.media-content-modal--fullscreen {
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 0;

  footer {
    display: none;
  }
}

.mot-day-detail-modal {
  height: 90%;

  .close-modal-icon {
    color: var(--light-grey) !important;
  }
}

.edit-image-modal {
  height: 90%;
  min-height: 90%;
}

.confirmation-two-lines-modal {
  height: 100%;
  max-height: 400px;

  .mat-dialog-container {
    height: 100%;
  }
}

.sign-document-modal {
  height: auto;
}

.full-width-modal {
  .mat-dialog-container {
    width: 100%;
  }
}
