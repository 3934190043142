@import "src/theme/exports";

.u-lb,
.u-lm,
.u-ls {
  margin: 0;
  padding: 0;
  color: var(--green);
  cursor: pointer;
  outline: 0 !important;

  &.center {
    text-align: center;
  }

  &:hover:not(.no-hover) {
    @include color-hover-desktop(var(--dark-green));
  }

  &.medium-grey {
    color: var(--medium-grey);

    &:hover {
      @include color-hover-desktop(var(--medium-grey));
    }
  }
}

.u-lb {
  font-size: 14px;
  font-weight: var(--gotham-medium);
  line-height: 22px;
  letter-spacing: -0.5px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 20px;
  }
}

.u-lm {
  font-size: 16px;
  font-weight: var(--gotham-medium);
  line-height: 18px;
  letter-spacing: -0.5px;
}

.u-ls {
  font-size: 14px;
  font-weight: var(--gotham-medium);
  line-height: 16px;
}

.u-lmenu {
  margin: 0;
  padding: 0;
  color: var(--light-grey);
  cursor: pointer;
  outline: 0 !important;
  font-size: 11px;
  font-weight: var(--gotham-medium);
  line-height: 11px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 14px;
    line-height: 16px;
  }

  &.green {
    color: var(--green);
  }

  &:hover {
    @include color-hover-desktop(var(--dark-green));
  }
}
