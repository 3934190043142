// Colors
.u-anthracite {
  color: var(--anthracite);
}

.u-dark-grey {
  color: var(--dark-grey);
}

.u-medium-grey {
  color: var(--medium-grey);
}

.u-light-grey {
  color: var(--light-grey);
}

// Display
.u-inline-block {
  display: inline-block;
}

// Text
.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

// Font Weight
.u-text-bold {
  font-weight: var(--gotham-bold);
}

.u-text-medium {
  font-weight: var(--gotham-medium);
}

.u-text-book {
  font-weight: var(--gotham-book);
}

.u-text-light {
  font-weight: var(--gotham-light);
}

// Max line to show

.u-clamp {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;

  &--c2 {
    -webkit-line-clamp: 2 !important;
  }
}
