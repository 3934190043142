@import "src/theme/exports";

:host {
  height: calc(100% - var(--header-height));

  @media (min-width: 768px) {
    height: calc(100% - var(--header-compact-height));
  }
}

main.content-container {
  --background-color: var(--secondary-100);

  height: 100%;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
