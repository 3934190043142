@import "material";

:root {
  // stylelint-disable
  --font-family-base: Gotham, -apple-system, "Helvetica Neue", "Roboto", sans-serif;
  // stylelint-enable

  /** Variables **/
  // --primary: #00ab61; // Deprecated: Use --green instead.
  --primary-light: #00aa39;
  // --primary-legend: #00754d; Deprecated: Use --dark-green instead.
  --primary-dark: #006731;
  --primary-darkest: #00482f;
  --primary-clear: #d0efe2;
  --primary-disabled: #59c898;
  --danger: #d0021b;
  --pending: #eaa02b;
  // --danger-2: #a51c30; Deprecated: Use --red instead.
  --secondary-1000: #000;
  --secondary-900: #04040f;
  --secondary-875: #0e2030;
  --secondary-860: #1d1d1d;
  --secondary-850: #121212;
  --secondary-825: #1d1d1d;
  --secondary-800: #213036;
  --secondary-715: #777;
  // --secondary-700: #4a4a4a; // Deprecated: Use --dark-grey instead.
  --secondary-675: #757575;
  --secondary-625: #666;
  // --secondary-600: #6f6f6f; // Deprecated: Use --medium-grey instead.
  // --secondary-500: #9d9d9d; // Deprecated: Use --light-grey instead.
  --secondary-550: #9e9e9e;
  --secondary-400: #a6a6a6;
  --secondary-350: #dadada;
  --secondary-300: #bdbdbd;
  --secondary-275: #c4c4c4;
  --secondary-250: #cfd8cf;
  --secondary-230: #c4c4c4;
  --secondary-225: #dfdfdf;
  --secondary-220: #e8e8e8;
  --secondary-210: #d8d8d8; // Deprecated: Use --very-light-pink instead.
  --secondary-200: #e9e9e9; // Deprecated: Use --very-light-pink-three instead.
  --secondary-175: #f9f9f9;
  --secondary-150: #ededed;
  --gray-151: #979797;
  --secondary-100: #f4f4f4;
  --secondary-50: #fafafa;
  --secondary-25: #fefefe;
  // --secondary: #fff; // Deprecated: Use --white instead.
  --rating-yellow: #ffbd00;
  --glass: rgba(33, 48, 54, 0.3);
  --alpha-gray: #9f9f9f;
  --gray-transparent: rgba(0, 0, 0, 0.45);
  --light-gray: rgba(0, 0, 0, 0.3);
  --background-gray: rgba(0, 0, 0, 0.06);
  --black-shadow: rgba(0, 0, 0, 1);
  --background-green-light: rgba(0, 171, 97, 0.1);
  --background-green-dark: #0f803e;
  --gray-150: #969696;
  --cerise: #f6c6cc;
  --golden-poppy: #fff5d9;
  --mary-gold: #f5ebd0;
  --downy: #eefbf5;
  --tropical-blue: #e9eff9;
  --scroll-up: #009a74;
  --white-four: #f7f7f7;
  --tooltip-border: #cfd8dc;
  --notification-color: #f4a10f;
  --highlight-color: #f4a10f;
  --advice-color: #fff5d9;
  --advice-color-button: #ffdd7f;
  --emoji-picker: rgba(236, 239, 241, 1);
  --emoji-picker-light: rgba(236, 239, 241, 0.85);
  --deep-teal: #01455c;
  --model-darken-gray: #ebebeb;
  --model-mountain-gray: #fbfbfb;
  --model-bg-gray: #e7e7e7;
  --model-bg-green: #23715b;
  --model-light-green: #9bbf5d;
  --bubbles-dark: #156838;
  --very-light-pink: #d8d8d8;
  --very-light-pink-three: #e9e9e9;
  // --blue-green-dark: #00482f; Deprecated: Use --darkest-green instead.
  --colors-primary: #488aff;
  --colors-secondary: #32db64;
  --colors-danger: #f53d3d;
  --colors-dark: #222;
  --colors-dark-badge: #19355e;

  /** Focus Outline **/
  --focus-outline-border-color: var(--green);
  --focus-outline-border-width: 0;
  --focus-outline-box-shadow: 0 0 3px 2px rgba(var(--green), 0.5);
  --refresher-height: 70px;
  --refresher-icon-color: var(--secondary-800);
  --refresher-text-color: var(--secondary-800);

  /** Heights **/
  --header-desktop-height: 112px;
  --header-height: 85px;
  --toolbar-height: 70px;
  --header-compact-height: 72px;
  --header-compact-transition: 0.4s linear;
  --menu-height: 50px;

  /** Font-Weight Vars **/
  --gotham-bold: 700;
  --gotham-medium: 500;
  --gotham-book: 400;
  --gotham-light: 300;

  /** Styleguide: Colors **/
  --anthracite: #1d1d1d;
  --dark-grey: #4a4a4a;
  --medium-grey: #6f6f6f;
  --regular-grey: #717171;
  --light-grey: #9d9d9d;
  --darkest-green: #00482f;
  --dark-green: #00754d;
  --green: #00ab61;
  --light-green: #e3eee7;
  --red: #a51c30;
  --status-red: #d14356;
  --status-blue: #59f;
  --model-red: #ba0018;
  --white: #fff;
  --black: #000;
  --darkmode-lightengrey: #909090;
  --darkmode-lightgrey: #5f5f5f;
  --darkmode-grey: #363636;
  --publication-dark: #ffffff4d;
  --border-grey: #cfcfcf;
  --border-light-blue: #d5d9e0;
}
