@import "src/theme/exports";

.grid,
.grid-fixed {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

  @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
    margin-right: unset;
    margin-left: unset;
    padding-right: unset;
    padding-left: unset;
    margin-inline: auto;
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }
}

.grid-fixed {
  width: 100%;
  max-width: 100%;

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }
}
