.c-button {
  width: 100%;
  height: 48px;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-weight: var(--gotham-bold);
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    color: var(--white);
    background-color: var(--green);

    &:hover,
    &:focus {
      background-color: var(--dark-green);
    }

    &:disabled {
      background-color: var(--primary-disabled);
    }
  }

  &.secondary {
    color: var(--green);
    background-color: var(--secondary-225);

    &:hover,
    &:focus {
      color: var(--darkest-green);
      background-color: var(--light-grey);
    }

    &:disabled {
      color: var(--secondary-400);
      background-color: var(--secondary-225);
    }
  }

  &.link {
    width: auto;
    color: var(--green);
    background-color: transparent;

    @include color-hover-desktop(var(--dark-green));

    &:disabled {
      color: var(--secondary-400);
    }
  }

  &.text-medium {
    font-size: 16px;
  }

  &.text-big {
    font-size: 18px;
  }
}
