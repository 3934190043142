.c-swiper {
  &--padded swiper-container {
    padding-bottom: 47px;
  }
}

:root {
  --swiper-pagination-color: var(--green);
  --swiper-pagination-bullet-inactive-color: var(--secondary-230);
  --swiper-pagination-bullet-inactive-opacity: 1;
}
