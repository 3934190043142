.c-info-card {
  width: auto;
  margin: 0 0 8px;
  border: 1px solid var(--secondary-200);
  border-radius: 6px;
  box-shadow: unset;
  background: var(--white);

  &__content {
    display: grid;
    grid-template-columns: min-content auto;
    grid-column-gap: 20px;
    padding: 16px;
    color: var(--medium-grey);
    font-size: 14px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      grid-template-columns: max-content auto;
    }

    .card-header + & {
      padding-top: 0;
      padding-bottom: 24px;
    }
  }

  &__content--right-align {
    grid-template-columns: auto max-content !important;
  }

  &__label {
    display: inline-block;
    min-width: 100px;
    min-height: 21px;
    white-space: normal;
  }

  &__value {
    &--danger {
      color: var(--red) !important;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 12px 0 16px;
    border-top: solid 1px var(--secondary-200);
    border-radius: 0 0 6px 6px;
    color: var(--secondary-800);

    .icon-trash {
      margin-left: 23px;
    }
  }

  &__footer-description {
    font-size: 17px;
    font-weight: var(--gotham-medium);
    line-height: 1;
  }

  &__footer-actions {
    display: flex;
    align-items: center;
    margin-right: 5px;
    color: var(--green);

    & i {
      cursor: pointer;
      font-size: 24px;

      @include color-hover-desktop(var(--primary-dark));
    }
  }
}
