.mat-radio-group {
  margin-top: 0 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--green);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: var(--grenn);
  background-color: var(--green);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}
