@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?oy4fgi");
  /* stylelint-disable */
  src: url("/assets/fonts/icomoon.eot?oy4fgi#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?oy4fgi") format("truetype"), url("/assets/fonts/icomoon.woff?oy4fgi") format("woff"),
    url("/assets/fonts/icomoon.svg?oy4fgi#icomoon") format("svg");
  /* stylelint-enable */
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin makeIcon($arg, $val) {
  .app-icon-#{$arg}::before {
    content: $val;
  }
}

[class^="app-icon-"],
[class*=" app-icon-"] {
  /* stylelint-disable */
  font-family: "icomoon" !important;
  speak: none;
  /* stylelint-enable */
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@include makeIcon(publish, "\e900");

/* @include makeIcon(five-components, "\e900"); */

@include makeIcon(mision-and-vision, "\e901");
@include makeIcon(model, "\e902");
@include makeIcon(paradigm, "\e903");
@include makeIcon(strong-ideas, "\e904");
@include makeIcon(universal-truths, "\e905");
@include makeIcon(home, "\e906");
@include makeIcon(library, "\e907");
@include makeIcon(favourites, "\e908");
@include makeIcon(arrow-down, "\e909");
@include makeIcon(arrow-up, "\e90a");
@include makeIcon(contents, "\e90b");
@include makeIcon(add, "\e90c");
@include makeIcon(arrow-right, "\e90d");
@include makeIcon(audio, "\e90e");
@include makeIcon(back, "\e910");
@include makeIcon(close, "\e911");
@include makeIcon(edit, "\e912");
@include makeIcon(empty-stars, "\e913");
@include makeIcon(examples, "\e914");
@include makeIcon(exit, "\e915");
@include makeIcon(heart, "\e916");
@include makeIcon(heart-outline, "\e917");
@include makeIcon(ic-full-screen, "\e918");
@include makeIcon(ic-play, "\e919");
@include makeIcon(ic-volume, "\e91a");
@include makeIcon(info, "\e91b");
@include makeIcon(library-add, "\e91c");
@include makeIcon(options, "\e91d");
@include makeIcon(play, "\e91e");
@include makeIcon(search, "\e91f");
@include makeIcon(shared-ideas, "\e920");
@include makeIcon(skip-next, "\e921");
@include makeIcon(skip-previous, "\e922");
@include makeIcon(star-five, "\e923");
@include makeIcon(star-four, "\e924");
@include makeIcon(star-one, "\e925");
@include makeIcon(star-three, "\e926");
@include makeIcon(star-two, "\e927");
@include makeIcon(video, "\e928");
@include makeIcon(volume-up, "\e92a");
@include makeIcon(content-audio, "\e92b");
@include makeIcon(private, "\e92c");
@include makeIcon(content-model, "\e92d");
@include makeIcon(text, "\e92e");
@include makeIcon(offline, "\e92f");
@include makeIcon(bin, "\e930");
@include makeIcon(broadcast, "\e931");
@include makeIcon(heart-filled, "\e932");
@include makeIcon(calc, "\e933");
@include makeIcon(time, "\e934");
@include makeIcon(listen, "\e935");
@include makeIcon(school, "\e936");
@include makeIcon(brain, "\e937");
@include makeIcon(work, "\e938");
@include makeIcon(pendulum, "\e939");
@include makeIcon(graph, "\e93a");
@include makeIcon(society, "\e93b");
@include makeIcon(lettuce, "\e93c");
@include makeIcon(glasses, "\e93d");
@include makeIcon(euro, "\e93f");
@include makeIcon(light, "\e940");
@include makeIcon(lighthouse, "\e941");
@include makeIcon(person, "\e942");
@include makeIcon(plane, "\e943");
@include makeIcon(sprout, "\e944");
@include makeIcon(treasure, "\e945");
@include makeIcon(worker, "\e946");
@include makeIcon(apple, "\e947");
@include makeIcon(alert, "\e948");
@include makeIcon(pending, "\e949");
@include makeIcon(arrow-left, "\e94a");
@include makeIcon(translate, "\e94b");
@include makeIcon(as-camera, "\e94c");
@include makeIcon(as-photos, "\e94d");
@include makeIcon(as-microphone, "\e94e");
@include makeIcon(as-folder, "\e94f");
@include makeIcon(as-video, "\e950");
@include makeIcon(denegated, "\e951");
@include makeIcon(comment, "\e952");
@include makeIcon(share, "\e953");
@include makeIcon(payslips, "\e954");
@include makeIcon(wage-garnishment, "\e955");
@include makeIcon(car, "\e956");
@include makeIcon(direct-debit, "\e957");
@include makeIcon(employment, "\e958");
@include makeIcon(permissions, "\e959");
@include makeIcon(remuneration, "\e95a");
@include makeIcon(irpf, "\e95c");
@include makeIcon(voluntary-withholding, "\e95d");
@include makeIcon(certificates, "\e960");
@include makeIcon(irpf-data, "\e962");
@include makeIcon(download-ep, "\e964");
@include makeIcon(readjustment, "\e95b");
@include makeIcon(personal-data, "\e95e");
@include makeIcon(edit-pencil, "\e95f");
@include makeIcon(round-question, "\e961");
@include makeIcon(happy-emoticon, "\e968");
@include makeIcon(mot-moon, "\e96a");
@include makeIcon(docpdf, "\e96b");
@include makeIcon(more, "\e96c");
@include makeIcon(language, "\e96d");
@include makeIcon(device-info, "\e96e");
@include makeIcon(terms-and-conditions, "\e96f");
@include makeIcon(cookies, "\e970");
@include makeIcon(data-treatment, "\e971");
@include makeIcon(personal-data-menu, "\e972");
@include makeIcon(horario-menu, "\e973");
@include makeIcon(my-holidays, "\e974");
@include makeIcon(payslips-menu, "\e975");
@include makeIcon(income-tax, "\e976");
@include makeIcon(certificates-menu, "\e977");
@include makeIcon(logout, "\e978");
@include makeIcon(multiple-neutral-2, "\e979");
@include makeIcon(app-icon-cog, "\e97a");
@include makeIcon(app-icon-build, "\e97b");
