@import "src/theme/exports";

ion-toast {
  --border-radius: 0;
  --box-shadow: 0;
  --button-color: var(--light-grey);
  --min-height: 155px;
  --min-width: 95%;

  white-space: pre-line;

  @media (min-width: map-get($grid-breakpoints, xs)) {
    --min-width: 336px;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    --min-height: 130px;
    --min-width: 336px;
    --max-width: 567px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    --min-height: 135px;
    --min-width: 750px;
  }

  &.info-toast {
    --background: var(--tropical-blue);
  }

  &.alert-toast {
    --background: var(--golden-poppy);
  }

  &.error-toast {
    --background: var(--cerise);
  }

  &.success-toast {
    --background: var(--downy);
  }

  &.simple-toast {
    --background: var(--white);
    --color: var(--secondary-675);
    --height: 46px;
    --min-height: 0;
    --width: fit-content;
    --min-width: 143px;
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--secondary-200);
    --border-radius: 2px;
    --box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  }

  &.ios {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      --min-width: 94%;
      --max-width: 94%;
      --right: 0;
      --left: 0;
    }
  }
}
