@mixin column-size() {
  &[size="1"] {
    --columns: 1;
  }

  &[offset="1"] {
    --offset: 1;
  }

  &[push="1"] {
    --push: 1;
  }

  &[pull="1"] {
    --pull: 1;
  }

  &[size="2"] {
    --columns: 2;
  }

  &[size="12/5"] {
    --columns: calc(12 / 5);
  }

  &[offset="2"] {
    --offset: 2;
  }

  &[push="2"] {
    --push: 2;
  }

  &[pull="2"] {
    --pull: 2;
  }

  &[size="3"] {
    --columns: 3;
  }

  &[offset="3"] {
    --offset: 3;
  }

  &[push="3"] {
    --push: 3;
  }

  &[pull="3"] {
    --pull: 3;
  }

  &[size="4"] {
    --columns: 4;
  }

  &[offset="4"] {
    --offset: 4;
  }

  &[push="4"] {
    --push: 4;
  }

  &[pull="4"] {
    --pull: 4;
  }

  &[size="5"] {
    --columns: 5;
  }

  &[offset="5"] {
    --offset: 5;
  }

  &[push="5"] {
    --push: 5;
  }

  &[pull="5"] {
    --pull: 5;
  }

  &[size="6"] {
    --columns: 6;
  }

  &[offset="6"] {
    --offset: 6;
  }

  &[push="6"] {
    --push: 6;
  }

  &[pull="6"] {
    --pull: 6;
  }

  &[size="7"] {
    --columns: 7;
  }

  &[offset="7"] {
    --offset: 7;
  }

  &[push="7"] {
    --push: 7;
  }

  &[pull="7"] {
    --pull: 7;
  }

  &[size="8"] {
    --columns: 8;
  }

  &[offset="8"] {
    --offset: 8;
  }

  &[push="8"] {
    --push: 8;
  }

  &[pull="8"] {
    --pull: 8;
  }

  &[size="9"] {
    --columns: 9;
  }

  &[offset="9"] {
    --offset: 9;
  }

  &[push="9"] {
    --push: 9;
  }

  &[pull="9"] {
    --pull: 9;
  }

  &[size="10"] {
    --columns: 10;
  }

  &[offset="10"] {
    --offset: 10;
  }

  &[push="10"] {
    --push: 10;
  }

  &[pull="10"] {
    --pull: 10;
  }

  &[size="11"] {
    --columns: 11;
  }

  &[offset="11"] {
    --offset: 11;
  }

  &[push="11"] {
    --push: 11;
  }

  &[pull="11"] {
    --pull: 11;
  }

  &[size="12"] {
    --columns: 12;
  }

  &[offset="12"] {
    --offset: 12;
  }

  &[push="12"] {
    --push: 12;
  }

  &[pull="12"] {
    --pull: 12;
  }
}

@mixin column-xs-size() {
  &[size-xs="1"] {
    --columns: 1;
  }

  &[offset-xs="1"] {
    --offset: 1;
  }

  &[push-xs="1"] {
    --push: 1;
  }

  &[pull-xs="1"] {
    --pull: 1;
  }

  &[size-xs="2"] {
    --columns: 2;
  }

  &[offset-xs="2"] {
    --offset: 2;
  }

  &[push-xs="2"] {
    --push: 2;
  }

  &[pull-xs="2"] {
    --pull: 2;
  }

  &[size-xs="3"] {
    --columns: 3;
  }

  &[offset-xs="3"] {
    --offset: 3;
  }

  &[push-xs="3"] {
    --push: 3;
  }

  &[pull-xs="3"] {
    --pull: 3;
  }

  &[size-xs="4"] {
    --columns: 4;
  }

  &[offset-xs="4"] {
    --offset: 4;
  }

  &[push-xs="4"] {
    --push: 4;
  }

  &[pull-xs="4"] {
    --pull: 4;
  }

  &[size-xs="5"] {
    --columns: 5;
  }

  &[offset-xs="5"] {
    --offset: 5;
  }

  &[push-xs="5"] {
    --push: 5;
  }

  &[pull-xs="5"] {
    --pull: 5;
  }

  &[size-xs="6"] {
    --columns: 6;
  }

  &[offset-xs="6"] {
    --offset: 6;
  }

  &[push-xs="6"] {
    --push: 6;
  }

  &[pull-xs="6"] {
    --pull: 6;
  }

  &[size-xs="7"] {
    --columns: 7;
  }

  &[offset-xs="7"] {
    --offset: 7;
  }

  &[push-xs="7"] {
    --push: 7;
  }

  &[pull-xs="7"] {
    --pull: 7;
  }

  &[size-xs="8"] {
    --columns: 8;
  }

  &[offset-xs="8"] {
    --offset: 8;
  }

  &[push-xs="8"] {
    --push: 8;
  }

  &[pull-xs="8"] {
    --pull: 8;
  }

  &[size-xs="9"] {
    --columns: 9;
  }

  &[offset-xs="9"] {
    --offset: 9;
  }

  &[push-xs="9"] {
    --push: 9;
  }

  &[pull-xs="9"] {
    --pull: 9;
  }

  &[size-xs="10"] {
    --columns: 10;
  }

  &[offset-xs="10"] {
    --offset: 10;
  }

  &[push-xs="10"] {
    --push: 10;
  }

  &[pull-xs="10"] {
    --pull: 10;
  }

  &[size-xs="11"] {
    --columns: 11;
  }

  &[offset-xs="11"] {
    --offset: 11;
  }

  &[push-xs="11"] {
    --push: 11;
  }

  &[pull-xs="11"] {
    --pull: 11;
  }

  &[size-xs="12"] {
    --columns: 12;
  }

  &[offset-xs="12"] {
    --offset: 12;
  }

  &[push-xs="12"] {
    --push: 12;
  }

  &[pull-xs="12"] {
    --pull: 12;
  }
}

@mixin column-md-size() {
  &[size-md="1"] {
    --columns: 1;
  }

  &[offset-md="1"] {
    --offset: 1;
  }

  &[push-md="1"] {
    --push: 1;
  }

  &[pull-md="1"] {
    --pull: 1;
  }

  &[size-md="2"] {
    --columns: 2;
  }

  &[offset-md="2"] {
    --offset: 2;
  }

  &[push-md="2"] {
    --push: 2;
  }

  &[pull-md="2"] {
    --pull: 2;
  }

  &[size-md="3"] {
    --columns: 3;
  }

  &[offset-md="3"] {
    --offset: 3;
  }

  &[push-md="3"] {
    --push: 3;
  }

  &[pull-md="3"] {
    --pull: 3;
  }

  &[size-md="4"] {
    --columns: 4;
  }

  &[offset-md="4"] {
    --offset: 4;
  }

  &[push-md="4"] {
    --push: 4;
  }

  &[pull-md="4"] {
    --pull: 4;
  }

  &[size-md="5"] {
    --columns: 5;
  }

  &[offset-md="5"] {
    --offset: 5;
  }

  &[push-md="5"] {
    --push: 5;
  }

  &[pull-md="5"] {
    --pull: 5;
  }

  &[size-md="6"] {
    --columns: 6;
  }

  &[offset-md="6"] {
    --offset: 6;
  }

  &[push-md="6"] {
    --push: 6;
  }

  &[pull-md="6"] {
    --pull: 6;
  }

  &[size-md="7"] {
    --columns: 7;
  }

  &[offset-md="7"] {
    --offset: 7;
  }

  &[push-md="7"] {
    --push: 7;
  }

  &[pull-md="7"] {
    --pull: 7;
  }

  &[size-md="8"] {
    --columns: 8;
  }

  &[offset-md="8"] {
    --offset: 8;
  }

  &[push-md="8"] {
    --push: 8;
  }

  &[pull-md="8"] {
    --pull: 8;
  }

  &[size-md="9"] {
    --columns: 9;
  }

  &[offset-md="9"] {
    --offset: 9;
  }

  &[push-md="9"] {
    --push: 9;
  }

  &[pull-md="9"] {
    --pull: 9;
  }

  &[size-md="10"] {
    --columns: 10;
  }

  &[offset-md="10"] {
    --offset: 10;
  }

  &[push-md="10"] {
    --push: 10;
  }

  &[pull-md="10"] {
    --pull: 10;
  }

  &[size-md="11"] {
    --columns: 11;
  }

  &[offset-md="11"] {
    --offset: 11;
  }

  &[push-md="11"] {
    --push: 11;
  }

  &[pull-md="11"] {
    --pull: 11;
  }

  &[size-md="12"] {
    --columns: 12;
  }

  &[offset-md="12"] {
    --offset: 12;
  }

  &[push-md="12"] {
    --push: 12;
  }

  &[pull-md="12"] {
    --pull: 12;
  }
}

@mixin column-lg-size() {
  &[size-lg="1"] {
    --columns: 1;
  }

  &[offset-lg="1"] {
    --offset: 1;
  }

  &[push-lg="1"] {
    --push: 1;
  }

  &[pull-lg="1"] {
    --pull: 1;
  }

  &[size-lg="2"] {
    --columns: 2;
  }

  &[offset-lg="2"] {
    --offset: 2;
  }

  &[push-lg="2"] {
    --push: 2;
  }

  &[pull-lg="2"] {
    --pull: 2;
  }

  &[size-lg="3"] {
    --columns: 3;
  }

  &[offset-lg="3"] {
    --offset: 3;
  }

  &[push-lg="3"] {
    --push: 3;
  }

  &[pull-lg="3"] {
    --pull: 3;
  }

  &[size-lg="4"] {
    --columns: 4;
  }

  &[offset-lg="4"] {
    --offset: 4;
  }

  &[push-lg="4"] {
    --push: 4;
  }

  &[pull-lg="4"] {
    --pull: 4;
  }

  &[size-lg="5"] {
    --columns: 5;
  }

  &[offset-lg="5"] {
    --offset: 5;
  }

  &[push-lg="5"] {
    --push: 5;
  }

  &[pull-lg="5"] {
    --pull: 5;
  }

  &[size-lg="6"] {
    --columns: 6;
  }

  &[offset-lg="6"] {
    --offset: 6;
  }

  &[push-lg="6"] {
    --push: 6;
  }

  &[pull-lg="6"] {
    --pull: 6;
  }

  &[size-lg="7"] {
    --columns: 7;
  }

  &[offset-lg="7"] {
    --offset: 7;
  }

  &[push-lg="7"] {
    --push: 7;
  }

  &[pull-lg="7"] {
    --pull: 7;
  }

  &[size-lg="8"] {
    --columns: 8;
  }

  &[offset-lg="8"] {
    --offset: 8;
  }

  &[push-lg="8"] {
    --push: 8;
  }

  &[pull-lg="8"] {
    --pull: 8;
  }

  &[size-lg="9"] {
    --columns: 9;
  }

  &[offset-lg="9"] {
    --offset: 9;
  }

  &[push-lg="9"] {
    --push: 9;
  }

  &[pull-lg="9"] {
    --pull: 9;
  }

  &[size-lg="10"] {
    --columns: 10;
  }

  &[offset-lg="10"] {
    --offset: 10;
  }

  &[push-lg="10"] {
    --push: 10;
  }

  &[pull-lg="10"] {
    --pull: 10;
  }

  &[size-lg="11"] {
    --columns: 11;
  }

  &[offset-lg="11"] {
    --offset: 11;
  }

  &[push-lg="11"] {
    --push: 11;
  }

  &[pull-lg="11"] {
    --pull: 11;
  }

  &[size-lg="12"] {
    --columns: 12;
  }

  &[offset-lg="12"] {
    --offset: 12;
  }

  &[push-lg="12"] {
    --push: 12;
  }

  &[pull-lg="12"] {
    --pull: 12;
  }
}

@mixin column-xl-size() {
  &[size-xl="1"] {
    --columns: 1;
  }

  &[offset-xl="1"] {
    --offset: 1;
  }

  &[push-xl="1"] {
    --push: 1;
  }

  &[pull-xl="1"] {
    --pull: 1;
  }

  &[size-xl="2"] {
    --columns: 2;
  }

  &[offset-xl="2"] {
    --offset: 2;
  }

  &[push-xl="2"] {
    --push: 2;
  }

  &[pull-xl="2"] {
    --pull: 2;
  }

  &[size-xl="3"] {
    --columns: 3;
  }

  &[offset-xl="3"] {
    --offset: 3;
  }

  &[push-xl="3"] {
    --push: 3;
  }

  &[pull-xl="3"] {
    --pull: 3;
  }

  &[size-xl="4"] {
    --columns: 4;
  }

  &[offset-xl="4"] {
    --offset: 4;
  }

  &[push-xl="4"] {
    --push: 4;
  }

  &[pull-xl="4"] {
    --pull: 4;
  }

  &[size-xl="5"] {
    --columns: 5;
  }

  &[offset-xl="5"] {
    --offset: 5;
  }

  &[push-xl="5"] {
    --push: 5;
  }

  &[pull-xl="5"] {
    --pull: 5;
  }

  &[size-xl="6"] {
    --columns: 6;
  }

  &[offset-xl="6"] {
    --offset: 6;
  }

  &[push-xl="6"] {
    --push: 6;
  }

  &[pull-xl="6"] {
    --pull: 6;
  }

  &[size-xl="7"] {
    --columns: 7;
  }

  &[offset-xl="7"] {
    --offset: 7;
  }

  &[push-xl="7"] {
    --push: 7;
  }

  &[pull-xl="7"] {
    --pull: 7;
  }

  &[size-xl="8"] {
    --columns: 8;
  }

  &[offset-xl="8"] {
    --offset: 8;
  }

  &[push-xl="8"] {
    --push: 8;
  }

  &[pull-xl="8"] {
    --pull: 8;
  }

  &[size-xl="9"] {
    --columns: 9;
  }

  &[offset-xl="9"] {
    --offset: 9;
  }

  &[push-xl="9"] {
    --push: 9;
  }

  &[pull-xl="9"] {
    --pull: 9;
  }

  &[size-xl="10"] {
    --columns: 10;
  }

  &[offset-xl="10"] {
    --offset: 10;
  }

  &[push-xl="10"] {
    --push: 10;
  }

  &[pull-xl="10"] {
    --pull: 10;
  }

  &[size-xl="11"] {
    --columns: 11;
  }

  &[offset-xl="11"] {
    --offset: 11;
  }

  &[push-xl="11"] {
    --push: 11;
  }

  &[pull-xl="11"] {
    --pull: 11;
  }

  &[size-xl="12"] {
    --columns: 12;
  }

  &[offset-xl="12"] {
    --offset: 12;
  }

  &[push-xl="12"] {
    --push: 12;
  }

  &[pull-xl="12"] {
    --pull: 12;
  }
}
