@import "src/theme/exports";

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6,
.u-h7,
.u-h8,
.u-h9 {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-base);
}

.u-h1 {
  color: var(--anthracite);
  font-size: 24px;
  font-weight: var(--gotham-bold);
  line-height: 36px;
  letter-spacing: -0.5px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 34px;
  }
}

.u-h2 {
  color: var(--anthracite);
  font-size: 20px;
  font-weight: var(--gotham-medium);
  line-height: 32px;
  letter-spacing: -0.5px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 30px;
  }
}

.u-h3 {
  color: var(--anthracite);
  font-size: 20px;
  font-weight: var(--gotham-book);
  line-height: 30px;
  letter-spacing: -0.5px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 28px;
  }
}

.u-h4 {
  color: var(--anthracite);
  font-size: 16px;
  font-weight: var(--gotham-bold);
  line-height: 26px;
  letter-spacing: -0.5px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 24px;
  }
}

.u-h5 {
  color: var(--anthracite);
  font-size: 20px;
  font-weight: var(--gotham-medium);
  line-height: 22px;
  letter-spacing: -0.5px;
}

.u-h6 {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: var(--gotham-medium);
  line-height: 18px;
  letter-spacing: 0;

  // Overwrite Ionic Styles
  &.u-important {
    color: var(--dark-grey) !important;
    font-size: 16px !important;
    font-weight: var(--gotham-medium) !important;
    line-height: 18px !important;
    letter-spacing: 0 !important;
  }
}

.u-h7 {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: var(--gotham-bold);
  line-height: 16px;
  letter-spacing: 0;
}

.u-h8 {
  color: var(--medium-grey);
  font-size: 14px;
  font-weight: var(--gotham-book);
  line-height: normal;
  letter-spacing: -0.2px;
}

.u-h9 {
  color: var(--medium-grey);
  font-size: 20px;
  font-weight: var(--gotham-medium);
  line-height: 20px;
  letter-spacing: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 24px;
  }
}
