.c-data-section {
  margin-top: 40px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    margin-top: 32px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: solid 1px var(--secondary-250);
  }

  &__action {
    &#{&} {
      // Doubles specificity of this selector so it applies over .u-ls in the template
      margin-left: auto;
    }
  }

  &__content {
    padding-top: 16px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-top: 24px;
    }
  }
}

.c-data-item {
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label-text {
    &#{&} {
      // Doubles specificity of this selector so it applies over .u-p3 in the template
      line-height: 20px;
    }
  }

  & + & {
    margin-top: 16px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 24px;
    }
  }
}
