@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Ultra.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-BlackIta.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-BoldIta.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-MediumIta.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-BookIta.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-LightIta.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-ThinIta.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "AndaleMono";
  src: url("/assets/fonts/AndaleMono.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
