@import "src/theme/exports";

emoji-mart {
  .emoji-mart {
    display: flex;
    flex-direction: column-reverse;
    width: 340px !important;
    height: 240px;
    box-shadow: 0 2px 4px 0 var(--gray-transparent);
    background-color: var(--emoji-picker-light);

    &-bar {
      border-top-width: 1px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    &-emoji {
      padding: 10px !important;
    }

    &-emoji:focus {
      outline: 0;
    }

    &-anchor:hover {
      color: var(--colors-primary) !important;
      cursor: pointer;
    }

    &-anchor-selected {
      color: var(--colors-primary) !important;

      .emoji-mart-anchor-bar {
        display: none;
      }
    }

    &-category-label span {
      color: var(--medium-grey);
      background-color: var(--emoji-picker) !important;
      font-size: 14px;
      font-weight: var(--gotham-light);
    }
  }
}
